import React from 'react'; //, { useState, useContext } 
//import { Label, Form, Input, Button } from 'reactstrap';
import '../css/cardetail.scss';
import ICar from '../interfaces/ICar';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import Cars from '../constants/Cars';
import Material from '../components/Material';

type TParams = { id: string | undefined };

// interface Props {
//     car: ICar | undefined;
// }


// const CarDetail = ({car} : Props) => {
const CarDetail = ({ match }: RouteComponentProps<TParams>) => {
    //   const [form, setForm] = useState<IContactForm>(defaultContactForm);
    // const [error, setError] = useState(undefined);
    const id = match.params.id;
    const filterFn = (item: ICar) => item.id == id;

    //  console.log('selected car', selectedCar);
    //  console.log('url', match.url);
    console.log('id', id);
    const cars = Cars.filter(filterFn);
    const car = cars[0];

    return (
        cars.length > 0
            ?
            <div className="car-detail content-width">
                <h1>{car.marque}</h1>
                <img src={`/${car.image}`} alt={car.imageAlt} />
                <p>Nombres de places : {car.nbPlaces}</p>
                {car.equipement.length > 0 ?
                    <Material equipements={car.equipement} />
                    : <div/>
                }
            </div>
            :
            <Redirect to="/cars" />
    )
}

export default CarDetail;
