import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '../css/booking.scss';

interface Props {
  lightId: string;
  lightName: string;
  lightComment: string;
}

// const renderIndicator = (
//   clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
//   isSelected: boolean,
//   index: number,
//   label: string
// ) => React.ReactNode;


const Booking = () => (//) = ({lightId, lightName, lightComment} : Props) => (
  //style="background-color: rgba(0, 0, 0, 0.5); padding: 10px; color: rgb(255, 255, 255); line-height: 50px; letter-spacing: 0px; font-size: 40px; text-align: center; transition: none 0s ease 0s; border-width: 0px; margin: 0px; font-weight: 400;">
  //style="margin: 10px 0px; cursor: pointer; border: 0px; width: 300px; height: 60px; transition: none 0s ease 0s; line-height: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;" width="300" height="60" data-no-lazy="1"> 
  //<script type="text/javascript" style="transition: none 0s ease 0s; line-height: 50px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;">var scheduling = {server: 'conversiontoolbox.net', button: '112391692', lang: 'fr'};</script>
  //<script type="text/javascript" src="https://conversiontoolbox.net/web/scheduling.js" style="transition: none 0s ease 0s; line-height: 50px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;"></script>
  <div className="booking">
    <div>
      <Carousel 
      infiniteLoop={true} 
      showThumbs={false} 
      autoPlay={true} 
      emulateTouch={true} 
      swipeable={true}
      useKeyboardArrows={true}
      showStatus={false}
      // renderIndicator={renderIndicator}
      >
        <div id="koffi-transport" className="carousel-item">
          <div className="content">
            <Link to="contact">
              <div className="inner-content">
                Koffi Transports : Votre service de transport dans la région de Charleroi <br />
                <img id="scheduling-17983227" src="images/rdv.png" alt="Bouton réserver maintenant" />
                {/* <p className="legend">Legend 1</p> */}
              </div>
            </Link>
          </div>
        </div>
        <div id="transport-aeroport" className="carousel-item">
          <div className="content">
            <Link to="contact">
              <div className="inner-content">
                Transport vers les aéroports et les gares de Belgique et des pays aux alentours<br />
                <img id="scheduling-17983227" src="images/rdv.png" alt="Bouton réserver maintenant" />
                {/* <p className="legend">Legend 2</p> */}
              </div>
            </Link>
          </div>
        </div>
        <div id="disponible-24h24-7j7" className="carousel-item">
          <div className="content">
            <Link to="contact">
              <div className="inner-content">
                Disponible 24h/24 et 7j/7 en cas d'urgence <br />
                <img id="scheduling-17983227" src="images/rdv.png" alt="Bouton réserver maintenant" />
                {/* <p className="legend">Legend 3</p> */}
              </div>
            </Link>
          </div>
        </div>
      </Carousel>
    </div>
  </div>
  //onclick="openScheduling(17983227)"
);

export default Booking;
