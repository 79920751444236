import React, { useState } from 'react';
import Car from '../components/Car';
import CarDetail from '../components/CarDetail';
import CarViewer from '../components/CarViewer';
import '../css/cars.scss';
import ICar from '../interfaces/ICar';
 import { Link, RouteComponentProps } from 'react-router-dom';
 import Cars from '../constants/Cars';

 type TParams = { id: string | undefined };



const CarsPage = ( { match }: RouteComponentProps<TParams>) => {

  //  const [selectedCar, selectCar] = useState<ICar>();
  //  const id = match.params.id;
  //  const filterFn = (item:ICar) => item.id == id;

  //  console.log('selected car', selectedCar);
  //  console.log('url', match.url);
  //  console.log('id', id);

  //  const cars = Cars.filter(filterFn);
  //  if(cars.length > 0)
  //  selectCar(cars[0]);
  return (
    <div id="cars">
      {/* selectedCar !== undefined ? */}
        <CarViewer url="/cars" />
          {/* :
        { selectedCar !== undefined ?
            <CarDetail car={selectedCar} />
              :
            <div />
        } */}
    </div>
  )
};

export default CarsPage;
