import React from 'react';
import Booking from '../components/Booking';
import Trust from '../components/Trust';
import Material from '../components/Material';
import CarViewer from '../components/CarViewer';
import ToKnow from '../components/ToKnow';
import '../css/home.scss';
import IEquipement from '../interfaces/IEquipement';

// import '../css/tooltip.scss';

const equipement1 : IEquipement = {
  nom: undefined,
  photo: 'images/equipements-siege-monceau-sur-sambre.jpg',
  alt: 'Sièges enfants',
  definition: 'Un siège bébé et un rehausseur enfant est à votre disposition'
};
const equipement2 : IEquipement = {
  nom: undefined,
  photo: 'images/Koffi-navette-privee-charleroi.jpg',
  alt: 'Navette privée',
  definition: 'Place disponible pour 8 personnes + leurs bagages'
};
const equipements : IEquipement[] = [ equipement1, equipement2]


const Home = () => {

  return (
    <div id="home">
      <Booking />
      <div className="home_service">
        <div className="home_service_area content-width">
          <div className="home_service_left">
            <h1>VTC à Charleroi</h1>
            <h2>La société Koffi Transports est un service de navettes situé à Charleroi.</h2>
            <p>Je vous propose mes services partout en Belgique, dans le nord de la France, en Allemagne (Düsseldorf), au Luxembourg et au Pays-Bas (Amsterdam).<br />
              Que ce soit pour des trajets longues ou courtes distances, je suis à votre disposition pour vous mener à bon port.<br />
              C’est donc, avec professionnalisme et rigueur que je viens vous chercher pour ensuite vous déposer à l’endroit que vous souhaitez.</p>
          </div>
          <div className="home_service_right">
            <img src="images/koffi-transports-charleroi-300x116.png" alt="Logo Koffi Transports" />
          </div>
        </div>
      </div>
      <Trust />
      {/* <CarViewer url="cars" /> */}
      <Material equipements={equipements} />
      <ToKnow />
    </div>
  )
};

export default Home;
