import React from 'react';
import {Link} from 'react-router-dom';
import Tracks from '../components/Tracks';
import '../css/services.scss'

// import '../css/tooltip.scss';

const Services = () => {
  return (
    <div id="services">
      <div id="title">
        <h1>CHAUFFEUR PRIVE A CHARLEROI</h1>
      </div>
      <div className="jumbo">
        <h2 className="content-width">Koffi Transports vous propose des transports en navette dans toute la Belgique et les pays aux alentours.</h2>
      </div>
      <Tracks />
      <Link to="contact" className="booknow">
        <img id="scheduling-17983227" src="images/rdv.png" alt="Bouton réservez maintenant" />
      </Link>
    </div>
  )
};

export default Services;
