import Home from '../pages/Home';
import Services from '../pages/Services';
import Contact from '../pages/Contact';
import Cars from '../pages/CarsPage';
import CarDetail from '../components/CarDetail';
/*
import All from '../pages/All';
import Contaminations from '../pages/Contaminations';
import Hospitalisations from '../pages/Hospitalisations';
import Deces from '../pages/Deces';
import Ecart from '../pages/Ecart';
*/
const Routes = [
  { url: '/', exact: true, comp: Home },
  { url: '/chauffeur-prive', exact: false, comp: Services },
  { url: '/contact', exact: false, comp: Contact },
  { url: '/cars/:id', exact: true, comp: CarDetail },
  { url: '/cars', exact: true, comp: Cars } ,
  /*
  { url: '/all', exact: false, comp: All },
  { url: '/contaminations', exact: false, comp: Contaminations },
  { url: '/hospitalisations', exact: false, comp: Hospitalisations },
  { url: '/deces', exact: false, comp: Deces },
  { url: '/ecart', exact: false, comp: Ecart },
  */
];

export default Routes;
