import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './constants/Routes';
import IRoute from './interfaces/IRoute';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './css/koffi.scss';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  const RenderRoute = (route: IRoute) => (
    route.exact
      ? <Route key={route.url} path={route.url} exact component={route.comp} />
      : <Route key={route.url} path={route.url} component={route.comp} />
  );

  return (
    <div id="app">
      <Router>
        <Header />
        <div id="content">
          <ScrollToTop>
            <Switch>
              {Routes.map((route) => RenderRoute(route))}
              <Redirect to="/" />
            </Switch>
          </ScrollToTop>

        </div>
        {/* <Legend /> */}
        {/* <NewLegend /> */}
        {/* <Footnote /> */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
