const Home = {
  name: 'Accueil',
  type: 'home',
  url: '',
  img: [],
};
const Services = {
  name: 'Services',
  type: 'services',
  url: 'chauffeur-prive',
  img: [],
};
const Contact = {
  name: 'Contact',
  type: 'contact',
  url: 'contact',
  img: [],
};
const Cars = {
  name: 'Véhicules',
  type: 'cars',
  url: 'cars',
  img: [],
};
/*
const All = {
  name: 'Tous les facteurs',
  type: 'all',
  url: 'all',
  img: [],
  more: {
    event: 'Surface Duo: October Update',
    location: 'aka.ms/surfaceduo',
    timing: 'October 6',
    day_num: '10',
    day_text: 'Saturday',
  }
};
const Contaminations = {
  name: 'Contaminations',
  type: 'contaminations',
  img: [
    {
      url: 'http://blog.usidistrib.be/images/apps/mm.png',
      alt: ''
    },
    {
      url: 'http://blog.usidistrib.be/images/apps/om.png',
      alt: ''
    },
    {
      url: 'http://blog.usidistrib.be/images/apps/mc.png',
      alt: ''
    },
    {
      url: 'http://blog.usidistrib.be/images/apps/mm.png',
      alt: ''
    },
  ],
  url: 'contaminations',
  more: undefined
};
const Hospitalisations = {
  name: 'Hospitalisations',
  type: 'hospitalisations',
  url: 'hospitalisations',
  img: [{
    url: '/images/music.png',
    alt: 'Pic of a headset',
  }],
  more: undefined,
};
const Deces = {
  name: 'Décès',
  type: 'deces',
  url: 'deces',
  img: [{
    url: '/images/videos.png',
    alt: 'Pic of a headset',
  }],
  more: undefined
};
const Ecart = {
  name: 'Écart',
  type: 'ecart',
  url: 'ecart',
  img: [{
    url: '/images/sports.png',
    alt: 'Pic of a headset',
  }],
  more: undefined
};
*/
const Links = [
  Home,
  Services,
  Contact,
  // Cars,
  /*
  All,
  Contaminations,
  Hospitalisations,
  Deces,
  Ecart,
*/
];

export default Links;
