import React from 'react';
import {Route, Link} from 'react-router-dom';
import '../css/carviewer.scss';
import Cars from '../constants/Cars';

interface IProps {
  url: string;
  // id: string | undefined;
}

const CarViewer = ({url}:IProps) => (//) = ({lightId, lightName, lightComment} : Props) => (
  <div className="car-viewer">
    <h1>Nos véhicules</h1>
    {
    <div className="cars-items content-width">
      {Cars.map((car) => { 
        return <div key={car.marque} className="car-item">
          <Link to={`${url}/${car.id}`}>
            <div className="car-item-encart">
              <img src={car.image} alt={car.imageAlt} className="vertical-center" />
            </div>
            <p>{car.marque}</p>
          </Link>
        </div> 
        })
      }
      </div>
    }
    <Route path={`${url}/:id`} component={CarViewer} />
  </div>
);

export default CarViewer;
