import React from 'react';
import '../css/trust.scss';

const Trust = () => (//) = ({lightId, lightName, lightComment} : Props) => (
  //style="background-color: rgba(0, 0, 0, 0.5); padding: 10px; color: rgb(255, 255, 255); line-height: 50px; letter-spacing: 0px; font-size: 40px; text-align: center; transition: none 0s ease 0s; border-width: 0px; margin: 0px; font-weight: 400;">
  //style="margin: 10px 0px; cursor: pointer; border: 0px; width: 300px; height: 60px; transition: none 0s ease 0s; line-height: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;" width="300" height="60" data-no-lazy="1"> 
  //<script type="text/javascript" style="transition: none 0s ease 0s; line-height: 50px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;">var scheduling = {server: 'conversiontoolbox.net', button: '112391692', lang: 'fr'};</script>
  //<script type="text/javascript" src="https://conversiontoolbox.net/web/scheduling.js" style="transition: none 0s ease 0s; line-height: 50px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;"></script>
  <div className="trust">
    <div className="content-width">
      <h1>Pourquoi me faire confiance ?</h1>
      <div className="trust-boxes">
        <div className="trust-box flex3">
          <span className="icon user" />
          <h2>Confiance</h2>
          <p>Je suis une personne de confiance. Je respecte mes clients et ne m’immisce pas dans leur vie privée. Je suis, par ailleurs, toujours à l’écoute pour vous procurer des conseils ou simplement pour discuter.</p>
        </div>
        <div className="trust-box flex3">
        <span className="icon watch" />
          <h2>Ponctualité</h2>
          <p>Etre à l’heure est une valeur primordiale pour moi. Je respecte scrupuleusement les heures et les délais fixés.</p>
        </div>
        <div className="trust-box flex3">
        <span className="icon smile" />
          <h2>Sourire et sympathie</h2>
          <p>Je suis agréable à vivre et de nature souriante. Je réponds à vos questions et essaye de vous apporter de la bonne humeur durant toute la durée du voyage en ma compagnie.</p>
        </div>
        <div className="trust-box flex2">
        <span className="icon car" />
          <h2>Expérience</h2>
          <p>Je suis un conducteur expérimenté. Je conduis depuis de nombreuses années et possède une licence en tant que « taxi collectif (TC) » et une autre en tant que « location de voiture avec chauffeur (LVC) ».</p>
        </div>
        <div className="trust-box flex2">
        <span className="icon clock" />
          <h2>Disponibilité</h2>
          <p>Je suis disponible 24h/24 et 7j/7. Des déplacements sont possibles en cas d’urgence.</p>
        </div>
      </div>
    </div>
  </div>
);

export default Trust;
