import React from 'react';
import ContactForm from '../components/ContactForm';
import '../css/contact.scss';

const Contact = () => {

  return (
    <div id="contact">
      {/* <div id="title">
        <h1>Covid 19 - Belgique</h1>
      </div> */}
      <div id="title">
        <h1>CONTACT</h1>
      </div>
      <div className="jumbo">
        <div className="flex content-width">
          <div className="jumbo-left">
            <p className="footer-phone"><span className="icon phone-out" /> 0473/19.94.70</p>
            {/* <p className="footer-surtaxe">(Appel local non surtaxé)</p> */}
            {/* <Link to="contact"> */}
              <img src="images/rappel.png" alt="Bouton on vous rappelle gratuitement" />
            {/* </Link> */}
          </div>
          <div className="jumbo-right">
            <p><span className="icon mail-open" /> Email</p>
            <span className="footer-mail"> <a href="mailto:info@koffi.be">info@koffi.be</a></span><br />
          </div>
        </div>
      </div>
      <ContactForm />
      {/* <Link to="contact" className="booknow">
        <img id="scheduling-17983227" src="images/rdv.png" />
      </Link> */}
    </div>
  )
};

export default Contact;
