import React from 'react';
import '../css/material.scss';
import IEquipement from '../interfaces/IEquipement';

interface IProps {
  equipements: IEquipement[];
}

const Material = ({ equipements }: IProps) => (//) = ({lightId, lightName, lightComment} : Props) => (
  <div className="material">
    <h1>Les équipements</h1>
    <div className="materials content-width">
      {equipements.map((equipement: IEquipement) => 
        (
          <div className="material-item">
            {equipement.nom === undefined ? <></> : <h2>{equipement.nom}</h2>}
            <img src={equipement.photo} alt={equipement.alt} />
            <p>{equipement.definition}</p>
          </div>
      )
      )
      }
    </div>
  </div>
);

export default Material;
