import ICar from '../interfaces/ICar';

const Mercedes1 : ICar = {
  id: '1',
  marque: 'Mercedes Class V',  
  image: 'images/cars/mercedes_classe_v.png',
  imageAlt: 'Sièges enfants',
  nbPlaces: 6,
  equipement: [
    {
      nom: 'Equipement 1',
      definition: 'Dans la Mercedes Class V vous y retrouverez tout le comfort propre à la marque allemande.',
      photo: '/images/cars/mercedes_classe_v.png',
      alt: 'equip 1',
    },
    {
      nom: 'Equipement 2',
      definition: 'Ceci est mon super équipement 2',
      photo: '/images/cars/mercedes_classe_v.png',
      alt: 'equip 2',
    },
    {
      nom: 'Equipement 3',
      definition: 'Ceci est mon super équipement 3',
      photo: '/images/cars/mercedes_classe_v.png',
      alt: 'equip 3',
    },
  ],
};
const Opel1 : ICar = {
  id: '2',
  marque: 'Opel Vivaro',  
  image: 'images/cars/opel_vivaro.jpg',
  imageAlt: 'Sièges enfants',
  nbPlaces: 6,
  equipement: [
    {
      nom: 'Equipement 1',
      definition: 'Dans la Mercedes Class V vous y retrouverez tout le comfort propre à la marque allemande.',
      photo: '/images/cars/opel_vivaro.jpg',
      alt: 'equip 1',
    },
    {
      nom: 'Equipement 2',
      definition: 'Ceci est mon super équipement 2',
      photo: '/images/cars/opel_vivaro.jpg',
      alt: 'equip 2',
    },
  ],
};
const Ford1 : ICar = {
  id: '3',
  marque: 'Ford Custom Tourneo',  
  image: 'images/cars/ford_custom_tourneo.png',
  imageAlt: 'Sièges enfants',
  nbPlaces: 6,
  equipement: [
    {
      nom: 'Equipement 1',
      definition: 'Le Ford Custom Tourneo est un grand véhicule silencieux et agréable. Parfait pour vous reposer durant un long voyage.',
      photo: '/images/cars/ford_custom_tourneo.png',
      alt: 'equip 1',
    },
  ],
};
const BMW1 : ICar = {
  id: '4',
  marque: 'BMW Series 5 Black',  
  image: 'images/cars/bmw_series5_black.jpg',
  imageAlt: 'Sièges enfants',
  nbPlaces: 3,
  equipement: [  ],
};
const Cars = [
  Mercedes1,
  Opel1,
  Ford1,
  BMW1,
];

export default Cars;
