
import React from 'react';
import '../css/toknow.scss';

const ToKnow = () => (//) = ({lightId, lightName, lightComment} : Props) => (
  //style="background-color: rgba(0, 0, 0, 0.5); padding: 10px; color: rgb(255, 255, 255); line-height: 50px; letter-spacing: 0px; font-size: 40px; text-align: center; transition: none 0s ease 0s; border-width: 0px; margin: 0px; font-weight: 400;">
  //style="margin: 10px 0px; cursor: pointer; border: 0px; width: 300px; height: 60px; transition: none 0s ease 0s; line-height: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;" width="300" height="60" data-no-lazy="1"> 
  //<script type="text/javascript" style="transition: none 0s ease 0s; line-height: 50px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;">var scheduling = {server: 'conversiontoolbox.net', button: '112391692', lang: 'fr'};</script>
  //<script type="text/javascript" src="https://conversiontoolbox.net/web/scheduling.js" style="transition: none 0s ease 0s; line-height: 50px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;"></script>
  <div className="to-know">
    <div className="content-width">
      <h1>A savoir</h1>
      <div className="to-know-boxes">
        <div className="to-know-box flex3">
          <img src="images/euro.png" alt="Billet d'euros" />
          <p>Paiement en cash ou par carte</p>
        </div>
        <div className="to-know-box flex3">
        <span className="icon mail" />
          <p>Un message vous sera envoyé pour vous confirmer mon arrivée</p>
        </div>
        <div className="to-know-box flex3">
        <span className="icon danger" />
          <p>Navette aéroport: Il est important d’allumer son téléphone à la sortie de l’avion!!</p>
        </div>
      </div>
      <img id="visa" src="images/navette-koffi-presentation.jpg" alt="Lecteur de carte VISA" loading="lazy" />
    </div>
  </div>
);

export default ToKnow;
