import React from 'react';
import '../css/tracks.scss';

const track = () => (//) = ({lightId, lightName, lightComment} : Props) => (
  //style="background-color: rgba(0, 0, 0, 0.5); padding: 10px; color: rgb(255, 255, 255); line-height: 50px; letter-spacing: 0px; font-size: 40px; text-align: center; transition: none 0s ease 0s; border-width: 0px; margin: 0px; font-weight: 400;">
  //style="margin: 10px 0px; cursor: pointer; border: 0px; width: 300px; height: 60px; transition: none 0s ease 0s; line-height: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;" width="300" height="60" data-no-lazy="1"> 
  //<script type="text/javascript" style="transition: none 0s ease 0s; line-height: 50px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;">var scheduling = {server: 'conversiontoolbox.net', button: '112391692', lang: 'fr'};</script>
  //<script type="text/javascript" src="https://conversiontoolbox.net/web/scheduling.js" style="transition: none 0s ease 0s; line-height: 50px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 40px;"></script>
  <div className="track">
    <div className="content-width">
      <h3>Nos différentes navettes :</h3>
      <div className="track-boxes">
        <div className="track-box flex3">
          <img src="images/services-gare-monceau-sur-sambre.jpg" alt="Gare Monceau sur sambre" />
          <h4>Navette aéroport et gare</h4>
          <p>Partez sans stress pour prendre votre vol ou votre train.  Je m’occupe de venir vous chercher et /ou vous déposer à l’aéroport ou la gare de votre choix.</p>
        </div>
        <div className="track-box flex3">
        <img src="images/services-evenements-monceau-sur-sambre.jpg" alt="Evenements à Monceau sur sambre" />
          <h4>Navette événements</h4>
          <p>Vous devez vous rendre à un vernissage ? A l’avant-première d’un film ou d’une pièce de théâtre? Un mariage ? Je mets à votre disposition ma voiture pour tous vos déplacements privés ou professionnels.</p>
        </div>
        <div className="track-box flex3">
        <img src="images/services-sorties-monceau-sur-sambre.jpg" alt="Sorties sur Monceau sur sambre" />
          <h4>Navette soirées</h4>
          <p>A la recherche d’un BOB pour vos soirées ou pour celles de vos enfants dans la région de Charleroi? Vous désirez profiter de vos soirées sans vous soucier de savoir qui reprendra le volant ?<br/>
          Je vous propose mes services pour vous emmener à vos sorties et vous ramener en toute sécurité à votre domicile.</p>
        </div>
        <div className="track-box flex2">
        <img src="images/services-rendez-vous-monceau-sur-sambre.jpg" alt="Rendez-vous à Monceau sur sambre" />
          <h4>Rendez-vous en journée</h4>
          <p>Vous devez vous rendre en urgence à un rendez-vous médical ou professionnel dans la région de Charleroi ? Vous n’avez pas de voiture à votre disposition pour vous y emmener ou pour rentrer chez vous ?<br/>
          Je vous emmène et/ou vient vous récupérer.</p>
        </div>
        <div className="track-box flex2">
        <img src="images/services-colis-monceau-sur-sambre.jpg" alt="Colis à partir de Monceau sur sambre" />
          <h4>Transport de colis</h4>
          <p>Vous voulez faire une surprise ou offrir un cadeau? Vous avez besoin d’envoyer des documents en urgence à votre bureau ?<br/>
          Je transporte vos colis en urgence et en toute discrétion aux endroits que vous désirez.</p>
        </div>
      </div>
    </div>
  </div>
);

export default track;
